import styled from 'styled-components';

import { Tablet } from '@/styles/layout';

const ServiceStepSection = () => {
  return (
    <Section>
      <Title>닥터나우, 어렵지 않아요</Title>
      <CardRow>
        <Card
          data-aos={'fade-up'}
          data-aos-duration={500}
          data-aos-once={'true'}
        >
          <CardTextContent>
            <Step>STEP 1</Step>
            <Label>진료 예약하기</Label>
          </CardTextContent>
          <Icon
            src={'https://file.doctornow.co.kr/landing/calendar-icon.png'}
            alt={'달력 아이콘'}
          />
        </Card>
        <Card
          data-aos={'fade-up'}
          data-aos-duration={1500}
          data-aos-once={'true'}
        >
          <CardTextContent>
            <Step>STEP 2</Step>
            <Label>전화로 진료받기</Label>
          </CardTextContent>
          <Icon
            src={'https://file.doctornow.co.kr/landing/call-icon.png'}
            alt={'전화 아이콘'}
          />
        </Card>
        <Card
          data-aos={'fade-up'}
          data-aos-duration={2000}
          data-aos-once={'true'}
        >
          <CardTextContent>
            <Step>STEP 3</Step>
            <Label>약 픽업하기</Label>
          </CardTextContent>
          <Icon
            src={'https://file.doctornow.co.kr/landing/pill-package-icon.png'}
            alt={'알약 아이콘'}
          />
        </Card>
      </CardRow>
    </Section>
  );
};

export default ServiceStepSection;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 68px;
  padding: 100px 20px 120px 20px;
  align-items: center;
  ${({ theme }) => `
    background: ${theme.color.gray20};
  `};

  @media screen and (max-width: ${Tablet}) {
    padding: 80px 20px;
  }
`;

const Title = styled.h2`
  ${({ theme }) => `
      color: ${theme.color.gray900};
      ${theme.typography.UI48B};
    `};

  @media screen and (max-width: ${Tablet}) {
    ${({ theme }) => `
       width: 200px;
      ${theme.typography.UI32B};
      text-align: center;
      align-self: center;
    `}
  }
`;

const CardRow = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @media screen and (max-width: ${Tablet}) {
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    justify-contents: stretch;
    gap: 20px;
  }
`;

const Card = styled.div`
  word-break: keep-all;
  text-align: center;
  width: 32%;
  background-color: white;
  border-radius: 20px;
  padding: 32px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.06);
  gap: 20px;

  @media screen and (max-width: ${Tablet}) {
    width: 100%;
  }
`;

const CardTextContent = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`;
const Step = styled.div`
  display: flex;
  height: 38px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  border-radius: 23px;

  ${({ theme }) => `
      background: ${theme.color.primary600};
      color: ${theme.color.white};
      ${theme.typography.UI18SB};
      
      @media screen and (max-width: ${Tablet}) {
       ${theme.typography.UI16SB}; 
      }
    `}
`;

const Label = styled.h3`
  ${({ theme }) => `
      color: ${theme.color.gray900};
      ${theme.typography.UI28B};
    `}
`;

const Icon = styled.img`
  width: 120px;
  height: 120px;
  margin-top: auto;

  @media screen and (max-width: ${Tablet}) {
    width: 100px;
    height: 100px;
  }
`;
