import { useEffect } from 'react';
import styled from 'styled-components';

import BottomBanner from '@/components/templates/landing/components/BottomBanner';
import BrandDescriptionSection from '@/components/templates/landing/components/BrandDescriptionSection';
import BrandStorySection from '@/components/templates/landing/components/BrandStorySection';
import ExposeLogger from '@/components/templates/landing/components/ExposeLogger';
import FaqSection from '@/components/templates/landing/components/FaqSection';
import MainBannerSection from '@/components/templates/landing/components/MainBannerSection';
import ReviewSection from '@/components/templates/landing/components/ReviewSection';
import ServiceCardSection from '@/components/templates/landing/components/ServiceCardSection';
import ServiceInstructionSection from '@/components/templates/landing/components/ServiceInstructionSection';
import ServiceStepSection from '@/components/templates/landing/components/ServiceStepSection';
import useLandingViewModel from '@/components/templates/landing/useLandingViewModel';
import { CustomPageProps } from '@/types/app';

const LandingTemplate = ({ client }: CustomPageProps) => {
  const viewModel = useLandingViewModel();

  useEffect(() => {
    Promise.allSettled([import('aos')]).then(([aosResult]) => {
      if (aosResult.status === 'fulfilled') {
        const AOS = aosResult.value;
        AOS.init();
      }
    });
  }, []);

  return (
    <Screen>
      <MainBannerSection />
      <ExposeLogger label={'main_banner'} client={client} />
      <BrandStorySection />
      <ExposeLogger label={'brand_description'} client={client} />
      <BrandDescriptionSection />
      <ExposeLogger label={'brand_story'} client={client} />
      <ServiceStepSection />
      <ExposeLogger label={'service_step'} client={client} />
      <ServiceInstructionSection />
      <ExposeLogger label={'service_instruction'} client={client} />
      <ReviewSection nestedReviews={viewModel.nestedReviews} />
      <ExposeLogger label={'review'} client={client} />
      <ServiceCardSection />
      <ExposeLogger label={'service_card'} client={client} />
      <FaqSection
        selectedFaqIndex={viewModel.openedFaqIndex}
        clickFaq={viewModel.openFaq}
      />
      <ExposeLogger label={'faq'} client={client} />
      <BottomBanner client={client} />
      <ExposeLogger label={'bottom_banner'} client={client} />
    </Screen>
  );
};
export default LandingTemplate;

const Screen = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  word-break: keep-all;
  position: relative;
  overflow-x: hidden;
  overscroll-behavior-x: none;

  div {
    z-index: 2;
  }

  div:first-child {
    z-index: 1;
  }
`;
