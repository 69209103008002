import styled, { css } from 'styled-components';

import { Tablet } from '@/styles/layout';
import { Client } from '@/types/app';
import Airbridge from '@/utils/airbridge';

const BottomBanner = ({ client }: { client: Client }) => {
  return (
    <Section>
      <BottomModelImg
        src={'https://file.doctornow.co.kr/landing/cf_model_bottom_banner.png'}
        alt={'이병헌 모델 하단 배너'}
      />
      <Content>
        <MainCopyContainer>
          <MainCopyHighlightText>
            500만<MainCopyText>이 선택한</MainCopyText>
          </MainCopyHighlightText>
          <MainBottomCopyText>대한민국 1위 비대면 진료 앱</MainBottomCopyText>
          <MainPrizeText>
            {'2024 주간동아 한국고객만족도 비대면진료앱 1위'}
          </MainPrizeText>
        </MainCopyContainer>
        <DownloadButton
          id={'footer-download'}
          onClick={() => {
            if (client.isMobile) {
              Airbridge.setDeepLink({
                buttonID: 'footer-download',
                deeplinkUrl: 'doctornow://',
                ctaParams: {
                  cta_param_1: 'homepage',
                  cta_param_2: 'ownedchannel',
                  cta_param_3: 'bottom_banner',
                },
              });
              return;
            }
            window.open('https://abr.ge/nry2rvf');
          }}
        >
          앱 다운로드
        </DownloadButton>
      </Content>
    </Section>
  );
};

export default BottomBanner;

const Section = styled.div`
  position: relative;
  width: 100%;
  height: 488px;

  background: linear-gradient(86deg, #ff7501 28.16%, #ff8a01 96.29%);
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${Tablet}) {
    background: linear-gradient(19deg, #ff7501 37.98%, #ff8801 96.07%);
    height: 866px;
  }
`;

const Content = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
  gap: 53px;
  padding: 0 20px;
  white-space: pre-wrap;

  @media screen and (max-width: ${Tablet}) {
    width: 300px;
    align-items: center;
    align-self: flex-start;
    margin-top: 72px;
    gap: 40px;
  }
`;

const MainCopyContainer = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;

  @media screen and (max-width: ${Tablet}) {
    gap: 0;
  }
`;

const MainPrizeText = styled.p`
  ${({ theme }) => css`
    ${theme.typography.UI13M};
    color: ${theme.color.primary300};
  `}
`;

const MainCopyText = styled.div`
  display: flex;
  flex-direction: row;
  ${({ theme }) => `
      ${theme.typography.UI56B};
      color: ${theme.color.white};
      
      @media screen and (max-width: ${Tablet}) { 
        text-align: center;
          justify-content: center;

        ${theme.typography.UI36B};
      } 
    `}
`;

const MainBottomCopyText = styled(MainCopyText)`
  margin-bottom: 26px;
  @media screen and (max-width: ${Tablet}) {
    margin-bottom: 20px;
  }
`;

const MainCopyHighlightText = styled(MainCopyText)`
  color: #fff931;
`;

const DownloadButton = styled.button`
  width: 200px;
  height: 56px;
  border-radius: 12px;
  padding: 0 12px;

  ${({ theme }) => `
    background: ${theme.color.white};
    color: ${theme.color.primary600};
    ${theme.typography.UI17SB};
  `}
`;

const BottomModelImg = styled.img`
  position: absolute;
  right: 30%;
  bottom: 0;
  width: 330px;
  object-fit: contain;
  transform: translateX(50%);
  @media screen and (max-width: ${Tablet}) {
    right: 50%;
  }
`;
